import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core'
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { InfoTiendaData } from '../../mock/tiendas'
import { ExcelService } from 'app/services/excelservice';
import { ServiceService } from 'app/services/service.service';
import { NgbDateStruct, NgbCalendar, NgbDate, NgbDateParserFormatter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { DiasMes } from "../../mock/diasmes";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  public fromDate: any
  public toDate: any
  public contentHeader: object
  model: NgbDateStruct;

  public selectMulti: Observable<any[]>;
  public selectMultiSelected = [{}];

  public hoveredDate: NgbDate | null = null;
  public loading = false;
  presupuestomes: any = 0;

  ventastotaldia: number = 0;
  ventastotalmes: any = 0;
  porcentajedia: number = 0;
  porcentajemes: number = 0;
  show: number = 0;
  show1: number = 0;
  porcentajefaltantedia: number = 0;
  porcentajefaltantemes: number = 0;

  presupuestomenosventasdia: number = 0;
  presupuestomenosventasmes: number = 0;
  mesames: any;
  nombretienda: string;
  cumplimientohistorico: any;
  cantidadVentasDia: any = 0;
  ventasFisicasActualDia: any = 0;
  ventasVirtualesActualDia: number = 0;
  cantidadVentasMesActual: any = 0;
  ventasFisicasMesActual: any = 0;
  ventasVirtualesMesActual: any = 0;
  devoluciones: any;
  ventasFisicasMesActual1: number = 0;
  ventasFisicasActualDia1: number = 0;
  devolucionesActualDia: number = 0;
  devolucionesMesActual: number = 0;
  cantidadVentasDigitaldia: number = 0;
  cantidadVentasDigitalMes: number = 0;
  presupuestoActualDigitalDia: number = 0;
  presupuestoDigitalMesActual: number = 0;
  porcentajeDigitaldia: number = 0;
  porcentajeDigitalmes: number = 0;
  cantidadArticulosTotalesDia: number = 0;
  cantidadArticulosTotalesMesActual: any = 0;
  cantidadTransaccionesMesActual: number = 0;
  cantidadTransaccionesDia: number = 0;
  ticketpromediodia: number = 0;
  ticketpromediomes: number = 0;
  articulosPorTicketDia: number = 0;
  articulosPorTicketMes: number = 0;
  totalCreditoKaiowaMesActual: number = 0;
  totalCreditoKaiowaDiaActual: number = 0;
  presupuestoCreditoKaiowaDia: number = 0;
  presupuestoKaiowaMesActual: number = 0;
  porcentajeDiaKaiowa: number = 0;
  porcentajeMesKaiowa: number = 0;

  formatos = [];
  tiendas = [];
  agencias = [];
  ciudades = [];
  responsables: any[];
  agencia: any = {};
  pendientekaiowa: number;
  pendientedigital: number;
  vpt: number = 0;
  upt: number = 0;
  pp: number = 0;
  vptsupt: number = 0;
  fechainicioStr: string;
  idTienda: number;
  fechainicio: Date;
  fechainidash: string;
  diadia: any;
  totalventas: number = 0;
  totalpresup: number = 0;
  totalporcent: number = 0;
  totalpendiente: number = 0;
  fechafin: Date;
  mostrarfiltros: boolean = true;
  fechafinStr: string;
  fechafindash: string;
  prespuestoentrefechas: number = 0;
  totalventasentrefechas: number = 0;
  porcentajeentrefechas: number = 0;
  porcentajefaltanteentrefechas: number = 0;
  presupuestomenosventasentrefechas: number = 0;
  mostrarfiltrosi: boolean = false;

  ventadigital: number = 0;
  ventafisica: number = 0;

  nombre: string = 'Faltante';
  totalDevoluciones: number = 0;
  ventafisica1: number = 0;
  cantidadVentas: number = 0;
  cantidadTransacciones: number = 0;
  cantidadArticulosTotales: number = 0;
  ticketpromedio: number = 0;
  articulosporticket: number = 0;
  totalCreditoKaiowa: number = 0;
  presupuestoCreditoKaiowa: number = 0;
  cumplimientoKaiowa: number = 0;
  presupuestoDigital: number = 0;
  cumplimientoRdl: number = 0;
  totaltotal: number;
  totaltotalventas: number = 0;
  totalpresupuesto: number = 0;
  totalcumplimiento: number = 0;
  totalpendiente1: number = 0;
  presupuestoFisico: number = 0;
  pendientedig: number = 0;
  ventassobretransacciones: number = 0;
  cumplimientovpt: number = 0;
  articulossobretransacciones: number = 0;
  precioprom: number = 0;
  vptsobreupt: number = 0;
  pendientecrkaiowa: number = 0;
  participacionfisico: number = 0;
  participaciondigital: number = 0;
  participaciontotal: number = 0;
  diar: any;
  codigoTienda: any = null;
  agenciaTienda: any;
  formatoTienda: any = null;
  vista: any = "Ac";
  ciudad: any = null;
  difecha: any;
  dif: number;
  // arregloAgencia: void;
  color: string;
  colorPf: boolean;
  colorCf: boolean;
  colorPd: boolean;
  colorPt: boolean;
  colorCd: boolean;
  colorCt: string;
  colorPvpt: boolean;
  colorPupt: boolean;
  colorPpp: boolean;
  colorPtc: boolean;

  colorPent: boolean;
  colorPkai: boolean;
  colorCvpt: boolean;
  colorCupt: boolean;
  colorCpp: boolean;
  colorCtc: boolean;
  colorCent: boolean;
  colorCkai: boolean;

  infoTiendaFiltrada: any;
  rol: string;
  mostrarCampo: boolean = true;

  coordinador: string;
  infoTiendaFiltradaAge: any;

  filtroAplicados: string[] = [];
  colorPfi: boolean;
  colorPdi: boolean;
  colorPtot: boolean;
  colorTrans: boolean;
  mostrarsincronizacion: boolean;
  mostrarVentas: boolean;
  mostrarIndicadores: boolean;
  mostrarPotenciales: boolean;
  colorTransTotal: string;
  colorTransDia: string;
  colorTransss: string;
  mostrarDetalleVentasFisicos: boolean = false;
  mostrarDetalleVentasDigitaless: boolean = false;
  mostrarDetalleVentasTotal: boolean = false;
  mostrarDetalleVentasCreditos: boolean = false;
  mostrarDetallesVPTs: boolean = false;
  mostrarDetallesUPTs: boolean = false;
  mostrarDetallesPPs: boolean = false;
  mostrarDetallesTCs: boolean = false;
  mostrarDetallesEntrada: boolean = false;
  flecharoja: boolean;
  flechaRojacDig: boolean;
  flecharojacfis: boolean;
  flechaRojaPdig: boolean;
  flecharojaPt: boolean;
  flechaRojaPfi: boolean;
  flechaRojaPdi: boolean;
  flechaRojaPvpt: boolean;
  flechaRojaCvpt: boolean;
  flechaRojaPupt: boolean;
  flechaRojaCupt: boolean;
  flechaRojaPpp: boolean;
  flechaRojaCpp: boolean;
  flechaRojaPtc: boolean;
  flechaRojaCtc: boolean;
  flechaRojaPent: boolean;
  flechaRojaCent: boolean;
  flechaRojaPkai: boolean;
  flechaRojaCkai: boolean;
  flecaRojaPtot: boolean;
  deshabilitado: boolean = false;
  flechaRojaTra: boolean;
  colorcTrans: boolean;
  flechaRojacTrans: boolean;
  desdehasta: string;
  codigoarray: any;
  mostrarDetalleTransacciones: boolean = false;
  mostrarDetallePartCreditos: boolean;
  fechaSincronizacionCam: any;
  intervalId: number;
  token: string;
  pTotal: any;
  pendTotal: any;
  excepciones: string[];
  variable: any;
  fechaAux: any;
  fechaAuxFin: any;
  consultaAnterior: number = 0;
  colorTransCredTotal: string;
  colorTransCred: boolean;
  flechaRojaTransCred: boolean;
  colorTranskai: boolean;
  flechaRojaTranskai: boolean;
  colorCumplimTotal: boolean;
  flechaRojaCumplimTotal: boolean;
  colorcrecCantCedValid: boolean;
  flechaRojacrecCantCedValid: boolean;
  colorCcrecim: boolean;
  flechaverdeCcrecim: boolean;
  flecharojaCcrecim: boolean;
  mostrado: boolean=false;
  counte: any;
  acumPptoVPT: any;
  dataEnviar: { vista: string; fechaInicio: string; fechaFin: string; tiendas: any; };
  estienda: boolean=false;
  pptoBaseTotal: any;
  pptoFisicoBaseTotal: any;
  pptoDigBaseTotal: any;
  colorPfr: boolean;
  flecharojaR: boolean;
  flechaRojaPdigR: boolean;
  colorPdR: boolean;
  pTotalRec: any;
  colorPtRec: boolean;
  flecharojaPtRec: boolean;


  constructor(private modalService:NgbModal,private diamess: DiasMes, private afMessaging: AngularFireMessaging, private calendar: NgbCalendar, public excelService: ExcelService, private router: Router, public formatter: NgbDateParserFormatter, private inforTiendas: InfoTiendaData, private service: ServiceService) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getToday();


  }


  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */

  onDateSelection(date: NgbDate, datepicker,modalBasic) {
    this.modalOpen(modalBasic)
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate) {
      this.toDate = date;
      datepicker.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    this.fechainicioStr = this.fromDate.year.toString() + '-' + this.fromDate.month.toString().padStart(2, '0') + '-' + this.fromDate.day.toString().padStart(2, '0');
    this.fechafinStr = this.toDate.year.toString() + '-' + this.toDate.month.toString().padStart(2, '0') + '-' + this.toDate.day.toString().padStart(2, '0');
    this.desdehasta = " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr
    this.fechainicio = new Date(this.fechainicioStr + 'T00:00');
    this.fechafin = new Date(this.fechafinStr + 'T00:00');
    if (this.fechafin.getTime() >= this.fechainicio.getTime()) {
      this.difecha = (this.fechainicio.getTime() - this.fechafin.getTime()) + (1);
      this.dif = (this.difecha / (1000 * 3600 * 24)) * (-1);
      // console.log("evento:" + event);
      if (this.dif <= 0) {
        this.vista = 'Ar'
      } else {
        this.vista = 'Ac'
      }
      return this.vista;
    } else {

      window.alert("seleccione un rango de fechas valido");

    }
  }
  seleccionarHoy() {
    this.consultaAnterior = 1
    this.vista = 'Ar';
    this.fromDate = this.calendar.getToday()
    this.toDate = this.calendar.getToday()
    this.fechainicioStr = this.fromDate.year.toString() + '-' + this.fromDate.month.toString().padStart(2, '0') + '-' + this.fromDate.day.toString().padStart(2, '0');
    this.fechafinStr = this.toDate.year.toString() + '-' + this.toDate.month.toString().padStart(2, '0') + '-' + this.toDate.day.toString().padStart(2, '0');
    this.desdehasta = " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr
  }
  seleccionarMesActual() {
    var ano: any
    var mes: any
    this.vista = 'Ac';
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getToday();
    var mesActual = this.calendar.getToday().month
    this.diamess.mes.map(x => {
      if (x.Mes == mesActual.toString().padStart(2, "0")) {
        this.fechainicioStr = this.fromDate.year.toString() + "-" + this.fromDate.month.toString().padStart(2, "0") + "-" + x.diaInicio;
        this.fechafinStr = this.fromDate.year.toString() + "-" + this.fromDate.month.toString().padStart(2, "0") + "-" + x.diaFin;
        ano = (this.fromDate.year).toString()
        mes = (mesActual).toString().padStart(2, "0")
        this.fromDate = { "day": parseInt(x.diaInicio), "month": parseInt(mes), "year": parseInt(ano) }
        this.toDate = { "day": parseInt(x.diaFin), "month": parseInt(mes), "year": parseInt(ano) }
      }
    })
    this.desdehasta = " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr;
  }
  modalOpen(modalXS) {
    if(this.mostrado==false){
      this.mostrado=true
      this.modalService.open(modalXS, {
        centered:true,
        size:'xs'
      });
    }
    
  }
  seleccionarAnterior() {
    this.vista = 'Ac';
    this.consultaAnterior = 1
    var ano: any
    var mes: any
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getToday();
    var mesActual = this.calendar.getToday().month
    if (mesActual.toString().padStart(2, "0") == "01") {
      mesActual = 13
    }
    this.diamess.mes.map(x => {
      if (x.Mes == (mesActual - 1).toString().padStart(2, "0")) {
        if (mesActual == 13) {
          this.fechainicioStr = (this.fromDate.year - 1).toString() + "-" + (mesActual - 1).toString().padStart(2, "0") + "-" + x.diaInicio;
          this.fechafinStr = (this.fromDate.year - 1).toString() + "-" + (mesActual - 1).toString().padStart(2, "0") + "-" + x.diaFin;
          ano = (this.fromDate.year - 1).toString()
          mes = (mesActual - 1).toString().padStart(2, "0")
          this.fromDate = { "day": parseInt(x.diaInicio), "month": parseInt(mes), "year": parseInt(ano) }
          this.toDate = { "day": parseInt(x.diaFin), "month": parseInt(mes), "year": parseInt(ano) }

        } else {
          this.fechainicioStr = this.fromDate.year.toString() + "-" + (mesActual - 1).toString().padStart(2, "0") + "-" + x.diaInicio;
          this.fechafinStr = this.toDate.year.toString() + "-" + (mesActual - 1).toString().padStart(2, "0") + "-" + x.diaFin;
          ano = this.toDate.year.toString()
          mes = (mesActual - 1).toString().padStart(2, "0")
          this.fromDate = { "day": parseInt(x.diaInicio), "month": parseInt(mes), "year": parseInt(ano) }
          this.toDate = { "day": parseInt(x.diaFin), "month": parseInt(mes), "year": parseInt(ano) }

        }

      }
    })

    this.desdehasta = " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr;
  }
  seleccionarAyer() {
    this.consultaAnterior = 1
    this.vista = 'Ar';
    this.fromDate = this.calendar.getNext(this.calendar.getToday(), 'd', -1);
    this.toDate = this.calendar.getNext(this.calendar.getToday(), 'd', -1);
    this.fechainicioStr = this.fromDate.year.toString() + '-' + this.fromDate.month.toString().padStart(2, '0') + '-' + this.fromDate.day.toString().padStart(2, '0');
    this.fechafinStr = this.toDate.year.toString() + '-' + this.toDate.month.toString().padStart(2, '0') + '-' + this.toDate.day.toString().padStart(2, '0');
    this.desdehasta = " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr
  }

  mostrarVentass() {
    this.mostrarVentas = true
    this.mostrarIndicadores = false
    this.mostrarPotenciales = false
  }
  mostrarIndicadoress() {
    this.mostrarVentas = false
    this.mostrarIndicadores = true
    this.mostrarPotenciales = false
  }
  mostrarPotencialess() {
    this.mostrarVentas = false
    this.mostrarIndicadores = false
    this.mostrarPotenciales = true
  }
  getToken() {
    this.afMessaging.getToken.subscribe(res => { this.token = res })

  }
  notificacion() {
    this.getToken();
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getToday();
    this.fechainicioStr = this.fromDate.year.toString() + '-' + this.fromDate.month.toString().padStart(2, '0') + '-' + this.fromDate.day.toString().padStart(2, '0');
    this.fechafinStr = this.toDate.year.toString() + '-' + this.toDate.month.toString().padStart(2, '0') + '-' + this.toDate.day.toString().padStart(2, '0');
    this.desdehasta = " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr
    this.buscarDatos();
    const options2: Intl.NumberFormatOptions = { style: 'currency', currency: 'USD' };
    const numberFormat2 = new Intl.NumberFormat('en-US', options2);
    let data = {
      "notification": {
        "title": " APP INDICADORES",
        "body": "Cumplimiento del día: " + this.pTotal.toFixed() + "%" + "\nPendiente del día: " + numberFormat2.format(Math.round(this.pendTotal.toFixed(0)))

      },
      "to": this.token,
      "direct_boot_ok": true
    }
    this.service.notificacion(data)
  }
  ngOnInit() {
    
    // // if(sessionStorage.getItem('responsable')=='Lineth Yanubis Sanchez Gaitan'){
    // //   this.router.navigate(['/panama/panama.component.html'])
    // //   return
    // // }
    this.excepciones = ["Julian Alberto Navarro Garcia","Leydi Milena Peña Sepulveda","Mariana Ossa Sanchez", "Juan Carlos Gallego Elejalde", "Melissa Castrillon Cano", "Jose Mario Tamayo Lopera", "Juan Carlos Maya Arbelaez","Carlos Ignacio Urrea", "Mauricio Andres Cardona Suarez", "Ana Lucia Hernandez Santa", "Alejandro Gonzalez Vasquez", "Maria Camila Cardona Arroyave", "Maria Cristina Londoño Cruz", "David  Gomez Patiño", "Soraya  Bedoya Mejia", "Margarita Maria Mazo Franco", "Luis Guillermo Maya Arbelaez","Sergio Alexander Villa Cano"];
    
    if (this.rol=="null" || this.rol==""){
      this.estienda=false
    }else{
      this.estienda=true
    }
    // var d = new Date();
    // var year = d.getFullYear();
    // var month = d.getMonth() + 1
    // var day = d.getDate();
    this.fechainicioStr = this.fromDate.year.toString() + '-' + this.fromDate.month.toString().padStart(2, '0') + '-' + this.fromDate.day.toString().padStart(2, '0');
    this.fechafinStr = this.toDate.year.toString() + '-' + this.toDate.month.toString().padStart(2, '0') + '-' + this.toDate.day.toString().padStart(2, '0');
    // this.fechainicioStr = year.toString() + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0');
    // this.fechafinStr = year.toString() + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0');
    this.desdehasta = " Desde " + this.fechainicioStr + " hasta " + this.fechafinStr
    this.coordinador = sessionStorage.getItem('responsable');
    this.mostrarVentas = false
    this.mostrarIndicadores = false
    this.mostrarPotenciales = false
    this.codigoTienda = null;
    this.agenciaTienda = null;
    this.formatoTienda = null;
    this.ciudad = null;
    this.rol = sessionStorage.getItem('rol');
    if (this.rol == 'null' || this.rol == undefined || this.rol=="") {
      this.intervalId = window.setInterval(() => this.notificacion(), 3600000);
      this.codigoTienda = parseInt(sessionStorage.getItem('idTienda'));
      this.mostrarCampo = false;
      this.mostrarfiltros = false;

    }
    this.nombretienda = sessionStorage.getItem('tienda');
    if (this.nombretienda == null) {
      this.router.navigate(['../pages/authentication/auth-login-v2/auth-login-v2.component.html'])
      return
    }

    if (this.rol == "COORDINADOR" && !this.excepciones.join(',').includes(this.coordinador)) {
      this.inforTiendas.infoTienda = this.inforTiendas.infoTiendaAll.filter(x => x.Responsable == this.coordinador);
      this.agenciaTienda = this.inforTiendas.infoTienda[0].Regional;
      if (this.agenciaTienda != '02') {
        this.deshabilitado = true;
      }


    }
    else if (this.rol == "GERENTE" && !this.excepciones.join(',').includes(this.coordinador)) {

      this.inforTiendas.infoTienda = this.inforTiendas.infoTiendaAll.filter(x => x.Gerente == this.coordinador);
      this.agenciaTienda = this.inforTiendas.infoTienda[0].Regional;
      if (this.agenciaTienda != '02') {
        this.deshabilitado = true;
      }

    }
    else
      this.inforTiendas.infoTienda = this.inforTiendas.infoTiendaAll;
    this.contentHeader = {
      headerTitle: 'Home',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Home',
            isLink: true,
            link: '/'
          },
          {
            name: 'Metas',
            isLink: false
          }
        ]
      }
    }
    if (this.rol == "GERENTE" && !this.excepciones.join(',').includes(this.coordinador)) {
      this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
      this.filterGer()
    }
    else
      if (this.rol == "COORDINADOR" && !this.excepciones.join(',').includes(this.coordinador)) {
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        this.filterResp()

      } else {
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        this.fillFilter(this.inforTiendas.infoTienda);
      }
  }
  private fillFilter(infotienda: any) {


    var agencia = infotienda.map(x => { return { codigoAgencia: x.Regional, nombreAgencia: x.NombreAgencia }; });
    var arregloAgencia = [];
    agencia.map((a) => {
      if (arregloAgencia.filter(p => p.codigoAgencia == a.codigoAgencia).length == 0)

        arregloAgencia.push(a);
    });
    // console.log(arregloAgencia);


    this.agencias = [...new Set(arregloAgencia)];
    this.formatos = [...new Set(infotienda.map(x => x.Formato))];
    this.ciudades = [...new Set(infotienda.map(x => x.CIUDAD))];
    this.responsables = [...new Set(infotienda.map(x => x.Responsable))];
    this.tiendas = [...new Set(infotienda.map(x => { return { Nombre: x.NOMBRE, Codigo: x.CodigoWinPOS }; }))];
    this.vista = this.vista;

  }




  mostrarDetallesdigital() {
    if (this.show1 == 0) {
      this.show1 = 1;
    } else {
      this.show1 = 0;
    }
  }
  mostrarDetalleVentasFisico() {
    if (this.mostrarDetalleVentasFisicos == false) {
      this.mostrarDetalleVentasFisicos = true
      this.mostrarDetalleVentasDigitaless = false
      this.mostrarDetalleVentasTotal = false
      this.mostrarDetalleVentasCreditos = false
      this.mostrarDetalleTransacciones = false
    } else {
      this.mostrarDetalleVentasFisicos = false
    }
  }
  mostrarDetalleVentasDigitales() {
    if (this.mostrarDetalleVentasDigitaless == false) {
      this.mostrarDetalleVentasFisicos = false
      this.mostrarDetalleVentasDigitaless = true
      this.mostrarDetalleVentasTotal = false
      this.mostrarDetalleVentasCreditos = false
      this.mostrarDetalleTransacciones = false
    } else {
      this.mostrarDetalleVentasDigitaless = false
    }
  }
  mostrarDetalleVentasTotales() {
    if (this.mostrarDetalleVentasTotal == false) {
      this.mostrarDetalleVentasTotal = true
      this.mostrarDetalleVentasFisicos = false
      this.mostrarDetalleVentasDigitaless = false
      this.mostrarDetalleVentasCreditos = false
      this.mostrarDetalleTransacciones = false
    } else {
      this.mostrarDetalleVentasTotal = false
    }
  }





  mostrarDetallesVPT() {
    if (this.mostrarDetallesVPTs == false) {
      this.mostrarDetallesVPTs = true
      this.mostrarDetallesUPTs = false
      this.mostrarDetallesPPs = false
      this.mostrarDetallesTCs = false
      this.mostrarDetallesEntrada = false
      this.mostrarDetalleVentasCreditos = false
      this.mostrarDetallePartCreditos = false
      this.mostrarDetalleTransacciones = false
    } else {
      this.mostrarDetallesVPTs = false
    }
  }
  mostrarDetallesUPT() {
    if (this.mostrarDetallesUPTs == false) {
      this.mostrarDetallesUPTs = true
      this.mostrarDetallesVPTs = false
      this.mostrarDetallesPPs = false
      this.mostrarDetallesTCs = false
      this.mostrarDetallesEntrada = false
      this.mostrarDetalleVentasCreditos = false
      this.mostrarDetallePartCreditos = false
      this.mostrarDetalleTransacciones = false
    } else {
      this.mostrarDetallesUPTs = false
    }
  }
  mostrarDetallesPP() {
    if (this.mostrarDetallesPPs == false) {
      this.mostrarDetallesPPs = true
      this.mostrarDetallesUPTs = false
      this.mostrarDetallesVPTs = false
      this.mostrarDetallesTCs = false
      this.mostrarDetallesEntrada = false
      this.mostrarDetalleVentasCreditos = false
      this.mostrarDetallePartCreditos = false
      this.mostrarDetalleTransacciones = false
    } else {
      this.mostrarDetallesPPs = false
    }
  }
  mostrarDetallesTC() {
    if (this.mostrarDetallesTCs == false) {
      this.mostrarDetallesTCs = true
      this.mostrarDetallesVPTs = false
      this.mostrarDetallesUPTs = false
      this.mostrarDetallesPPs = false
      this.mostrarDetallesEntrada = false
      this.mostrarDetalleVentasCreditos = false
      this.mostrarDetallePartCreditos = false
      this.mostrarDetalleTransacciones = false
    } else {
      this.mostrarDetallesTCs = false
    }
  }
  mostrarDetallesEntradas() {
    if (this.mostrarDetallesEntrada == false) {
      this.mostrarDetallesEntrada = true
      this.mostrarDetallesTCs = false
      this.mostrarDetallesVPTs = false
      this.mostrarDetallesUPTs = false
      this.mostrarDetallesPPs = false
      this.mostrarDetalleVentasCreditos = false
      this.mostrarDetallePartCreditos = false
      this.mostrarDetalleTransacciones = false
    } else {
      this.mostrarDetallesEntrada = false
    }
  }
  mostrarDetalleVentasCredito() {
    if (this.mostrarDetalleVentasCreditos == false) {
      this.mostrarDetalleVentasCreditos = true
      this.mostrarDetallesTCs = false
      this.mostrarDetallesVPTs = false
      this.mostrarDetallesUPTs = false
      this.mostrarDetallesPPs = false
      this.mostrarDetallesEntrada = false
      this.mostrarDetallePartCreditos = false
      this.mostrarDetalleTransacciones = false
    } else {
      this.mostrarDetalleVentasCreditos = false
    }
  }
  mostrarDetalleTransaccion() {
    if (this.mostrarDetalleTransacciones == false) {
      this.mostrarDetalleTransacciones = true
      this.mostrarDetallesTCs = false
      this.mostrarDetallesVPTs = false
      this.mostrarDetallesUPTs = false
      this.mostrarDetallesPPs = false
      this.mostrarDetallesEntrada = false
      this.mostrarDetallePartCreditos = false
      this.mostrarDetalleVentasCreditos = false
    } else {
      this.mostrarDetalleTransacciones = false
    }
  }
  mostrarDetallePartCredito() {
    if (this.mostrarDetallePartCreditos == false) {
      this.mostrarDetallePartCreditos = true
      this.mostrarDetallesTCs = false
      this.mostrarDetallesVPTs = false
      this.mostrarDetalleTransacciones = false
      this.mostrarDetallesUPTs = false
      this.mostrarDetallesPPs = false
      this.mostrarDetallesEntrada = false
      this.mostrarDetalleVentasCreditos = false
    } else {
      this.mostrarDetallePartCreditos = false
    }
  }

  buscarDatos() {
    this.loading = true;
    // if(this.consultaAnterior==1){
    //   this.consultaAnterior=0
    //   this.fechainicioStr = this.fechaAux.year.toString() + '-' + this.fechaAux.month.toString().padStart(2, '0') + '-' + this.fechaAux.day.toString().padStart(2, '0');
    //   this.fechafinStr = this.fechaAuxFin.year.toString() + '-' + this.fechaAuxFin.month.toString().padStart(2, '0') + '-' + this.fechaAuxFin.day.toString().padStart(2, '0');
    // }else{
    this.fechainicioStr = this.fromDate.year.toString() + '-' + this.fromDate.month.toString().padStart(2, '0') + '-' + this.fromDate.day.toString().padStart(2, '0');
    this.fechafinStr = this.toDate.year.toString() + '-' + this.toDate.month.toString().padStart(2, '0') + '-' + this.toDate.day.toString().padStart(2, '0');
    // }


    // if (this.formatoTienda == "LUMAR BY LEONISA") {
    //   this.formatoTienda = "MULTIMARCA";
    // }

    this.idTienda = this.codigoTienda;
    if (this.idTienda == -1) {
      this.idTienda = null;
    }
    if (this.agenciaTienda == -1) {
      this.agenciaTienda = null;
    }
    if (this.formatoTienda == -1) {
      this.formatoTienda = null;
    }
    if (this.ciudad == -1) {
      this.ciudad = null;
    }
    if (this.vista == undefined) {
      this.vista = "Ac";
    }




    this.fechainicio = new Date(this.fechainicioStr + 'T00:00');
    this.fechafin = new Date(this.fechafinStr + 'T00:00');
     // Obtener la fecha actual
     const fechaActual = new Date(); // Obtener la fecha actual
     fechaActual.setHours(0, 0, 0, 0)
     const ultimoDiaMes = new Date(fechaActual.getFullYear(), fechaActual.getMonth() + 1, 0); // Obtener la fecha final del mes actual
     const diaAnterior = new Date(fechaActual.getFullYear(), fechaActual.getMonth(), fechaActual.getDate() - 1); // Obtener el día anterior al mes actual
     const cantidadDiasFinMesaAyer = (ultimoDiaMes.getTime() - diaAnterior.getTime()) / (24 * 60 * 60 * 1000);
    this.difecha = (this.fechainicio.getTime() - this.fechafin.getTime());
    this.dif = ((this.difecha / (1000 * 3600 * 24)) * (-1) + 1);
    
    this.codigoarray = []
    if (this.rol == "null" || this.rol == "") {
      if (this.vista == "Ar") {
        if (this.codigoTienda != null) {
          this.codigoarray.push(this.codigoTienda)
          this.dataEnviar = {
            vista: "Ar",
            fechaInicio: this.fechainicioStr,
            fechaFin: this.fechafinStr,
            tiendas: this.codigoarray
          }
        } else {
          this.dataEnviar = {
            vista: "Ar",
            fechaInicio: this.fechainicioStr,
            fechaFin: this.fechafinStr,
            tiendas: this.infoTiendaFiltrada.map(r => r.CodigoWinPOS)
          }

        }
      } else {
        if (this.codigoTienda != null) {
          this.codigoarray.push(this.codigoTienda)
          this.dataEnviar = {
            vista: "Ac",
            fechaInicio: this.fechainicioStr,
            fechaFin: this.fechafinStr,
            tiendas: this.codigoarray
          }
        } else {
          this.dataEnviar = {
            vista: "Ac",
            fechaInicio: this.fechainicioStr,
            fechaFin: this.fechafinStr,
            tiendas: this.infoTiendaFiltrada.map(r => r.CodigoWinPOS)
          }

        }
      }
    } else {
      if (this.vista == "Ar") {
        if (this.codigoTienda != null) {
          this.dataEnviar = {
            vista: "Ar",
            fechaInicio: this.fechainicioStr,
            fechaFin: this.fechafinStr,
            tiendas: this.codigoTienda.map(r => r.Codigo)
          }
        } else {
          this.dataEnviar = {
            vista: "Ar",
            fechaInicio: this.fechainicioStr,
            fechaFin: this.fechafinStr,
            tiendas: this.infoTiendaFiltrada.map(r => r.CodigoWinPOS)
          }

        }
      } else {
        if (this.codigoTienda != null) {
          this.dataEnviar = {
            vista: "Ac",
            fechaInicio: this.fechainicioStr,
            fechaFin: this.fechafinStr,
            tiendas: this.codigoTienda.map(r => r.Codigo)
          }
        } else {
          this.dataEnviar = {
            vista: "Ac",
            fechaInicio: this.fechainicioStr,
            fechaFin: this.fechafinStr,
            tiendas:   this.infoTiendaFiltrada.map(r => r.CodigoWinPOS)
          }

        }
      }
    }



    this.fechainidash = this.fechainicio.getFullYear().toString() + '-' + (this.fechainicio.getMonth() + 1).toString().padStart(2, '0') + '-' + this.fechainicio.getDate().toString().padStart(2, '0')
    this.fechafindash = this.fechafin.getFullYear().toString() + '-' + (this.fechafin.getMonth() + 1).toString().padStart(2, '0') + '-' + this.fechafin.getDate().toString().padStart(2, '0')
    this.service.RDLIndicadoresAcumuladosMesMuchasTiendas(this.dataEnviar).then(result => {
      if (this.formatoTienda == "MULTIMARCA") {
        this.formatoTienda = "LUMAR BY LEONISA";
      }
      this.loading = false;
      if (this.agenciaTienda != -1 && this.agenciaTienda == '51' && this.idTienda == 678) {
        this.agenciaTienda = '02';
      } else if (this.agenciaTienda != -1 && this.agenciaTienda == '51' && this.idTienda == 679) {
        this.agenciaTienda = '04'
      }
      if (result.length > 0) {
        this.mostrarsincronizacion = true;
      }
      this.diar = result.meses.map(e => {
        this.counte=this.counte+1
        this.pptoBaseTotal=parseFloat(e.presupuestoFisico)+parseFloat(e.presupuestoDigital)
        this.pptoFisicoBaseTotal=parseFloat(e.presupuestoFisico)
        this.pptoDigBaseTotal=parseFloat(e.presupuestoDigital)
        // if(this.fechainicio < fechaActual){
          e["presupuestoFisicoRecalculado"] = e.prespuestoFisicoFinalReclaculado
          e["presupuestoDigitalRecalculado"]=e.prespuestoDigitalFinalReclaculado
          e["presupuestoTotalRecalculado"]=parseFloat(e["presupuestoFisicoRecalculado"])+parseFloat(e["prespuestoDigitalFinalReclaculado"])
          // e["totalPpto"]=(parseFloat(e.presupuestoFisico)+parseFloat(e.presupuestoDigital)).toString()

        // }else if(this.fechainicio >= fechaActual){
        //   if(parseFloat(e.pptoaAyerFis)-parseFloat(e.vtaaAyerFis)<=0){
        //     e["presupuestoFisico"] = e.presupuestoFisico
        //   }else{
        //     e["presupuestoFisico"] = ((((parseFloat(e.pptoaAyerFis)-parseFloat(e.vtaaAyerFis))/cantidadDiasFinMesaAyer)*((this.dif)))+this.pptoFisicoBaseTotal).toString()
        //   }
        //   if(parseFloat(e.pptoaAyerDig)-parseFloat(e.vtaaAyerDig)<=0){
        //     e["presupuestoDigital"]=e.presupuestoDigital
        //   }else{
        //     e["presupuestoDigital"]=((((parseFloat(e.pptoaAyerDig)-parseFloat(e.vtaaAyerDig))/cantidadDiasFinMesaAyer)*((this.dif)))+this.pptoDigBaseTotal).toString()
        //   }
        //   // if(parseFloat(e.pptoaAyer)-parseFloat(e.vtaaAyer)<=0){
        //   //   // e["totalPpto"]=(parseFloat(e.presupuestoFisico)+parseFloat(e.presupuestoDigital)).toString()
        //   // }else{
        //   //   // e["totalPpto"]=((((parseFloat(e.pptoaAyer)-parseFloat(e.vtaaAyer))/cantidadDiasFinMesaAyer)*(((this.dif)+1))+this.pptoBaseTotal)).toString()
        //   // }
          
          
          
        // }
        e["artFis"] = parseFloat(e.cantidadArticulosFisicos)
        e["tArt"] = parseFloat(e["artFis"]) + parseFloat(e.cantidadArticulosDigitales);
        e["tVentasFisicas"] = parseFloat(e.totalVentasFisicas) - parseFloat(e.totalDevoluciones);
        e["tVentasFisicasPasado"] = parseFloat(e.totalVentasFisicasPasado) - parseFloat(e.totalDevolucionesPasado);
        e["totalVentasRango"] = parseFloat(e.totalVentasFisicas) + parseFloat(e.totalVentasVirtuales) - parseFloat(e.totalDevoluciones);
        e["totalVentasRangoPasado"] = parseFloat(e.totalVentasFisicasPasado) + parseFloat(e.totalVentasVirtualesPasado) - parseFloat(e.totalDevolucionesPasado);
        e["totalArticulos"] = parseFloat(e.cantidadArticulosDigitales) + parseFloat(e.cantidadArticulosFisicos)
        e["totalArticulosPasado"] = parseFloat(e.cantidadArticulosDigitalesPasado) + parseFloat(e.cantidadArticulosFisicosPasado) - parseFloat(e.cantidadArticulosDevolucionesPasado);
        e["pFisico"] = ((parseFloat(e["tVentasFisicas"]) * 100) / parseFloat(e.presupuestoFisicoRecalculado));
        
        if (e["pFisico"] == Infinity) {
          e["pFisico"] = 0;
        }
        this.fechaSincronizacionCam = e.fechaSincronizacionCam
        if (e["pFisico"] >= 100) {
          this.colorPf = true
          this.flecharoja = false
        } else if (e["pFisico"] < 100 && e["pFisico"] >= 90) {
          this.flecharoja = true
          this.colorPf = false
        } else {
          this.flecharoja = true
          this.colorPf = false
        }
        e["pFisicoRec"] = ((parseFloat(e["tVentasFisicas"]) * 100) / parseFloat(e.presupuestoFisico));
        
        if (e["pFisicoRec"] == Infinity) {
          e["pFisicoRec"] = 0;
        }
         if (e["pFisicoRec"] >= 100) {
          this.colorPfr = true
          this.flecharojaR = false
        } else if (e["pFisicoRec"] < 100 && e["pFisicoRec"] >= 90) {
          this.flecharojaR = true
          this.colorPfr = false
        } else {
          this.flecharojaR = true
          this.colorPfr = false
        }
        e["cFisico"] = (((parseFloat(e["tVentasFisicas"]) * 100) / parseFloat(e["tVentasFisicasPasado"])) - 100);
        if (e["cFisico"] == Infinity) {
          e["cFisico"] = 0;
        }
        if (e["cFisico"] >= 2) {
          this.colorCf = true;
          this.flecharojacfis = false
        } else if (e["cFisico"] < 2 && e["cFisico"] >= 0) {
          this.colorCf = true;
          this.flecharojacfis = false
        } else {
          this.colorCf = false;
          this.flecharojacfis = true
        }
        e["partFisico"] = ((parseFloat(e["tVentasFisicas"]) * 100) / parseFloat(e["totalVentasRango"]));
        e["pendFisico"] = parseFloat(e.presupuestoFisicoRecalculado) - parseFloat(e["tVentasFisicas"]);
        if (e["pendFisico"] > 0) {
          this.colorPfi = false
          this.flechaRojaPfi = true
        } else if (e["pendFisico"] < 0) {
          this.colorPfi = true
          this.flechaRojaPfi = false
        } else {
          this.colorPfi = false
          this.flechaRojaPfi = false
        }
        e["pendientecrkaiowa"] = parseFloat(e.presupuestoKaiowa) - parseFloat(e.totalCreditoKaiowa);
        e["pDigital"] = ((parseFloat(e.totalVentasVirtuales) * 100) / parseFloat(e.presupuestoDigitalRecalculado));
        if (e["pDigital"] == Infinity) {
          e["pDigital"] = 0;
        }
        if (e["pDigital"] >= 100) {
          this.colorPd = true;
          this.flechaRojaPdig = false
        } else if (e["pDigital"] < 100 && e["pDigital"] >= 90) {
          this.colorPd = false;
          this.flechaRojaPdig = true
        } else {
          this.colorPd = false;
          this.flechaRojaPdig = true
        }
        e["pDigitalRec"] = ((parseFloat(e.totalVentasVirtuales) * 100) / parseFloat(e.presupuestoDigital));
        if (e["pDigitalRec"] == Infinity) {
          e["pDigitalRec"] = 0;
        }
        if (e["pDigitalRec"] >= 100) {
          this.colorPdR = true;
          this.flechaRojaPdigR = false
        } else if (e["pDigitalRec"] < 100 && e["pDigitalRec"] >= 90) {
          this.colorPdR = false;
          this.flechaRojaPdigR = true
        } else {
          this.colorPdR = false;
          this.flechaRojaPdigR = true
        }
        e["cDigital"] = ((parseFloat(e.totalVentasVirtuales) * 100) / parseFloat(e.totalVentasVirtualesPasado)) - 100;
        if (e["cDigital"] == Infinity) {
          e["cDigital"] = 0;
        }
        if (e["cDigital"] >= 2) {
          this.colorCd = true;
          this.flechaRojacDig = false;
        } else if (e["cDigital"] < 2 && e["cDigital"] >= 0) {
          this.colorCd = true;
          this.flechaRojacDig = false;
        } else {
          this.colorCd = false;
          this.flechaRojacDig = true;
        }
        e["partDigital"] = ((parseFloat(e.totalVentasVirtuales) * 100) / parseFloat(e["totalVentasRango"]));
        e["totalPpto"] = parseFloat(e.presupuestoDigital) + parseFloat(e.presupuestoFisico);
        e["pendDigital"] = parseFloat(e.presupuestoDigitalRecalculado) - parseFloat(e.totalVentasVirtuales);
        if (e["pendDigital"] > 0) {
          this.colorPdi = false;
          this.flechaRojaPdi = true
        } else if (e["pendDigital"] < 0) {
          this.colorPdi = true;
          this.flechaRojaPdi = false
        } else {
          this.colorPdi = false;
          this.flechaRojaPdi = false
        }
        e["pendTotal"] = parseFloat(e["pendDigital"]) + parseFloat(e["pendFisico"]);
        this.pendTotal = e["pendTotal"];
        if (e["pendTotal"] > 0) {
          this.colorPtot = false;
          this.flecaRojaPtot = true;
        } else if (e["pendTotal"] <= 0) {
          this.colorPtot = true;
          this.flecaRojaPtot = false;
        } else {
          this.colorPtot = true;
          this.flecaRojaPtot = false;
        }
        e["pTotal"] = ((parseFloat(e["totalVentasRango"]) * 100) / parseFloat(e["presupuestoTotalRecalculado"]));
        this.pTotal = e["pTotal"]
        if (e["pTotal"] == Infinity) {
          e["pTotal"] = 0;
        }
        if (e["pTotal"] >= 100) {
          this.colorPt = true;
          this.flecharojaPt = false
        } else if (e["pTotal"] < 100 && e["pTotal"] >= 90) {
          this.colorPt = false;
          this.flecharojaPt = true;
        } else {
          this.colorPt = false;
          this.flecharojaPt = true;
        }
        e["pTotalRec"] = ((parseFloat(e["totalVentasRango"]) * 100) / parseFloat(e["totalPpto"]));
        this.pTotalRec = e["pTotalRec"]
        if (e["pTotalRec"] == Infinity) {
          e["pTotalRec"] = 0;
        }
        if (e["pTotalRec"] >= 100) {
          this.colorPtRec = true;
          this.flecharojaPtRec = false
        } else if (e["pTotalRec"] < 100 && e["pTotalRec"] >= 90) {
          this.colorPtRec = false;
          this.flecharojaPtRec = true;
        } else {
          this.colorPtRec = false;
          this.flecharojaPtRec = true;
        }
        e["cTotal"] = ((parseFloat(e["totalVentasRango"]) * 100) / parseFloat(e["totalVentasRangoPasado"])) - 100;
        if (e["cTotal"] == Infinity) {
          e["cTotal"] = 0;
        }
        if (e["cTotal"] >= 0) {
          this.colorCumplimTotal = true;
          this.flechaRojaCumplimTotal = false;
        } else {
          this.colorCumplimTotal = false;
          this.flechaRojaCumplimTotal = true;
        }
        e["vpt"] = parseFloat(e["totalVentasRango"]) / parseFloat(e.numeroTransaccionesMes);
        e["vptPasado"] = parseFloat(e["totalVentasRangoPasado"]) / parseFloat(e.numeroTransaccionesMesPasado);
        if (e["vptPasado"] == Infinity) {
          e["vptPasado"] = 0;
        }
        e["upt"] = parseFloat(e["totalArticulos"]) / parseFloat(e.numeroTransaccionesMes);
        e["uptPasado"] = parseFloat(e["totalArticulosPasado"]) / parseFloat(e.numeroTransaccionesMesPasado);
        if (e["uptPasado"] == Infinity) {
          e["uptPasado"] = 0;
        }
        e["pP"] = parseFloat(e["totalVentasRango"]) / parseFloat(e["totalArticulos"]);
        e["pPPasado"] = parseFloat(e["totalVentasRangoPasado"]) / parseFloat(e["totalArticulosPasado"]);
        if (e["pPPasado"] == Infinity) {
          e["pPPasado"] = 0;
        }
        
       

          e["tC"] = ((parseFloat(e.numeroTransaccionesMes) - parseFloat(e.totalTransaccionesCarpa)) * 100) / (parseFloat(e.numeroEntradasMes));
        
        
        
        e["tCPasado"] = (parseFloat(e.numeroTransaccionesMesPasado) * 100) / parseFloat(e.numeroEntradasMesPasado);
        if (e["tCPasado"] == Infinity) {
          e["tCPasado"] = 0;
        }
        // e["prptoVPT"] = parseFloat(e["totalPpto"]) / parseFloat(e.PptoTrans);
        // ######################################################################################
        e["prptoVPT"] = e.PptoVPT/e.numeroDivisorPP
       
        
        e["cumVpt"] = ((e["vpt"] * 100) / parseFloat(e["prptoVPT"]));
        if (e["cumVpt"] == Infinity) {
          e["cumVpt"] = 0;
        }
        if (e["cumVpt"] >= 100) {
          this.colorPvpt = true;
          this.flechaRojaPvpt = false;
        } else if (e["cumVpt"] < 100 && e["cumVpt"] >= 90) {
          this.colorPvpt = false;
          this.flechaRojaPvpt = true;
        } else {
          this.colorPvpt = false;
          this.flechaRojaPvpt = true;
        }
        e["cVpt"] = ((e["vpt"] * 100) / parseFloat(e["vptPasado"])) - 100;
        if (e["cVpt"] == Infinity) {
          e["cVpt"] = 0;
        }
        if (e["cVpt"] >= 2) {
          this.colorCvpt = true;
          this.flechaRojaCvpt = false;
        } else if (e["cVpt"] < 2 && e["cVpt"] >= 0) {
          this.colorCvpt = true;
          this.flechaRojaCvpt = false;
        } else {
          this.colorCvpt = false;
          this.flechaRojaCvpt = true;
        }
        e["prptoUPT"] = parseFloat(e.PptoUnid) / parseFloat(e.PptoTrans);
        e["cumUpt"] = ((e["upt"] * 100) / parseFloat(e["prptoUPT"]));
        if (e["cumUpt"] == Infinity) {
          e["cumUpt"] = 0;
        }
        if (e["cumUpt"] >= 100) {
          this.colorPupt = true;
          this.flechaRojaPupt = false;
        } else if (e["cumUpt"] < 100 && e["cumUpt"] >= 90) {
          this.colorPupt = false;
          this.flechaRojaPupt = true;
        } else {
          this.colorPupt = false;
          this.flechaRojaPupt = true;
        }
        e["cUpt"] = (((e["upt"]) / parseFloat(e["uptPasado"])) - 1) * 100;
        if (e["cUpt"] == Infinity) {
          e["cUpt"] = 0;
        }

        if (e["cUpt"] >= 2) {
          this.colorCupt = true;
          this.flechaRojaCupt = false;
        } else if (e["cUpt"] < 2 && e["cUpt"] >= 0) {
          this.colorCupt = true;
          this.flechaRojaCupt = false;
        } else {
          this.colorCupt = false;
          this.flechaRojaCupt = true;
        }
        // e["prptoPP"] = parseFloat(e["prptoVPT"]) / parseFloat(e["prptoUPT"]);
        e["prptoPP"] = e.PptoPP / e.numeroDivisorPP;

        e["cumPp"] = ((e["pP"] * 100) / parseFloat(e["prptoPP"]));
        if (e["cumPp"] == Infinity) {
          e["cumPp"] = 0;
        }
        if (e["cumPp"] >= 100) {
          this.colorPpp = true;
          this.flechaRojaPpp = false;
        } else if (e["cumPp"] < 100 && e["cumPp"] >= 90) {
          this.colorPpp = false;
          this.flechaRojaPpp = true;
        } else {
          this.colorPpp = false;
          this.flechaRojaPpp = true;
        }

        e["cPp"] = ((e["pP"] * 100) / parseFloat(e["pPPasado"])) - 100;
        if (e["cPp"] == Infinity) {
          e["cPp"] = 0;
        }

        if (e["cPp"] >= 2) {
          this.colorCpp = true;
          this.flechaRojaCpp = false;
        } else if (e["cPp"] < 2 && e["cPp"] >= 0) {
          this.colorCpp = true;
          this.flechaRojaCpp = false;
        } else {
          this.colorCpp = false;
          this.flechaRojaCpp = true;
        }
        if(e.PptoTC==0){
          e["prptoTc"]=0
        }else{
          e["prptoTc"] = parseFloat(e.PptoTrans) * 100 / parseFloat(e.PptoEntradas);
        if(e["prptoTc"]==Infinity || Number.isNaN(e["prptoTc"])){
          e["prptoTc"]=0
        }}
        
        e["cumTrans"] = parseFloat(e.numeroTransaccionesMes) * 100 / parseFloat(e.PptoTrans);
        if (e["cumTrans"] >= 100) {
          this.colorTrans = true;
          this.flechaRojaTra = false;
        } else if (e["cumTrans"] < 100 && e["cumTrans"] >= 90) {
          this.colorTrans = false;
          this.flechaRojaTra = true;
        } else {
          this.colorTrans = false;
          this.flechaRojaTra = true;
        }
        e["cTrans"] = (parseFloat(e.numeroTransaccionesMes) * 100 / parseFloat(e.numeroTransaccionesMesPasado)) - 100;
        if (e["cTrans"] >= 2) {
          this.colorcTrans = true;
          this.flechaRojacTrans = false;
        } else if (e["cTrans"] < 2 && e["cTrans"] >= 0) {
          this.colorcTrans = true;
          this.flechaRojacTrans = false;
        } else {
          this.colorcTrans = false;
          this.flechaRojacTrans = true;
        }
        e["partCred"] = (e.totalCreditoKaiowa * 100) / parseFloat(e["totalVentasRango"])
        e["partCredPpto"] = (e.presupuestoKaiowa * 100) / parseFloat(e["totalPpto"])

        e["partCredpasado"] = (e.totalCreditoKaiowaPasado * 100) / parseFloat(e["totalVentasRangoPasado"])

        e["cumTc"] = ((e["tC"] * 100) / parseFloat(e["prptoTc"]));
        if (e["cumTc"] == Infinity) {
          e["cumTc"] = 0;
        }
        if (e["cumTc"] >= 100) {
          this.colorPtc = true;
          this.flechaRojaPtc = false;
        } else if (e["cumTc"] < 100 && e["cumTc"] >= 90) {
          this.colorPtc = false;
          this.flechaRojaPtc = true;
        } else {
          this.colorPtc = false;
          this.flechaRojaPtc = true;
        }

        e["presupTc"] = ((parseFloat(e.PptoTrans) * 100) / parseFloat(e.PptoEntradas));
        if (e["presupTc"] == Infinity) {
          e["presupTc"] = 0;
        }
        e["cTc"] = ((e["tC"] * 100) / parseFloat(e["tCPasado"])) - 100;
        if (e["cTc"] == Infinity) {
          e["cTc"] = 0;
        }
        if (e["cTc"] >= 2) {
          this.colorCtc = true;
          this.flechaRojaCtc = false;
        } else if (e["cTc"] < 2 && e["cTc"] >= 0) {
          this.colorCtc = true;
          this.flechaRojaCtc = false;
        } else {
          this.colorCtc = false;
          this.flechaRojaCtc = true;
        }
        e["cumEntradas"] = ((e.numeroEntradasMes * 100) / parseFloat(e.PptoEntradas));
        if (e["cumEntradas"] == Infinity) {
          e["cumEntradas"] = 0;
        }
        if (e["cumEntradas"] >= 100) {
          this.colorPent = true;
          this.flechaRojaPent = false;
        } else if (e["cumEntradas"] < 100 && e["cumEntradas"] >= 90) {
          this.colorPent = false;
          this.flechaRojaPent = true;
        } else {
          this.colorPent = false;
          this.flechaRojaPent = true;
        }
        e["cEntradas"] = ((parseFloat(e.numeroEntradasMes) * 100) / parseFloat(e.numeroEntradasMesPasado)) - 100;
        if (Number.isNaN(e["cEntradas"])) {
          e["cEntradas"] = 0;
        }
        if (e["cEntradas"] == Infinity) {
          e["cEntradas"] = 0;
        }
        if (e["cEntradas"] >= 2) {
          this.colorCent = true;
          this.flechaRojaCent = false;
        } else if (e["cEntradas"] < 2 && e["cEntradas"] >= 0) {
          this.colorCent = true;
          this.flechaRojaCent = false;
        } else {
          this.colorCent = false;
          this.flechaRojaCent = true;
        }

        e["cKaiowa"] = ((parseFloat(e.totalCreditoKaiowa) * 100) / parseFloat(e.totalCreditoKaiowaPasado)) - 100;
        if (e["cKaiowa"] == Infinity) {
          e["cKaiowa"] = 0;
        }
        if (e["cKaiowa"] >= 2) {
          this.colorCkai = true;
          this.flechaRojaCkai = false;
        } else if (e["cKaiowa"] < 2 && e["cKaiowa"] >= 0) {
          this.colorCkai = true;
          this.flechaRojaCkai = false;
        } else {
          this.colorCkai = false;
          this.flechaRojaCkai = true;
        }
        e["cumplimientoKaiowa"] = ((parseFloat(e.totalCreditoKaiowa) * 100) / parseFloat(e.presupuestoKaiowa));
        if (e["cumplimientoKaiowa"] == Infinity) {
          e["cumplimientoKaiowa"] = 0;
        }
        if (e["cumplimientoKaiowa"] >= 100) {
          this.colorPkai = true;
          this.flechaRojaPkai = false;
        } else if (e["cumplimientoKaiowa"] < 100 && e["cumplimientoKaiowa"] >= 90) {
          this.colorPkai = false;
          this.flechaRojaPkai = true;
        } else {
          this.colorPkai = false;
          this.flechaRojaPkai = true;
        }
        e["cedulasComodin"] = 100 - parseFloat(e.cantidadCedulasValidas)
        e["cedulasComodinPasado"] = 100 - parseFloat(e.cantidadCedulasValidasPasado)
        if (e["cedulasComodinPasado"] == 100) {
          e["cedulasComodinPasado"] = null
        }
        e["cedulasCrecim"] = (((e["cedulasComodin"]) * 100) / (e["cedulasComodinPasado"])) - 100
        if (e["cedulasCrecim"] == Infinity) {
          e["cedulasCrecim"] = "∞"
        } else if (e["cedulasCrecim"] > 0) {
          this.colorCcrecim = false;
          this.flecharojaCcrecim = true;
        } else {
          this.colorCcrecim = true;
          this.flecharojaCcrecim = false;
        }



        e["trans"] = (parseFloat(e.numeroEntradasMes) * (parseFloat(e["prptoTc"]) / 100)) - parseFloat(e.numeroTransaccionesMes);

        if (parseFloat(e.numeroEntradasMes) == 0 || parseFloat(e.numeroTransaccionesMes) == 0 || parseFloat(e["prptoTc"]) == 0 || isNaN(e["prptoTc"])) {
          e["trans"] = 0;
        }
        if (e["trans"] > 0) {
          this.colorTransss = 'colorRojo';
        } else if (e["trans"] == 0) {
          this.colorTransss = 'colorAmarillo'
        } else {
          this.colorTransss = 'colorVerde'
        }
        e["transDiario"] = e["trans"] / this.dif;
        if (e["transDiario"] > 0) {
          this.colorTransDia = 'colorRojo';
        } else if (e["transDiario"] == 0) {
          this.colorTransDia = 'colorAmarillo'
        } else {
          this.colorTransDia = 'colorVerde'
        }
        if (e["trans"] == 0) {
          e["transDiario"] = 0;
        }
        e["potTotal"] = e["trans"] * e["prptoVPT"];

        if (e["trans"] == 0) {
          e["potTotal"] = 0;
        }
        if (e["potTotal"] > 0) {
          this.colorTransTotal = 'colorRojo';
        } else if (e["potTotal"] == 0) {
          this.colorTransTotal = 'colorAmarillo'
        } else {
          this.colorTransTotal = 'colorVerde'
        }
        e["cumNumCred"] = (e.cantidadTransaccionesCreditos * 100) / e.PptoCreditoNro
        if (e["cumNumCred"] >= 100) {
          this.colorTranskai = true;
          this.flechaRojaTranskai = false;
        } else if (e["cumNumCred"] < 100 && e["cumNumCred"] >= 90) {
          this.colorTranskai = false;
          this.flechaRojaTranskai = true;
        } else {
          this.colorTranskai = false;
          this.flechaRojaTranskai = true;
        }
        e["crecNumCred"] = ((e.cantidadTransaccionesCreditos * 100) / e.cantidadTransaccionesCreditosPasado) - 100

        if (e["crecNumCred"] >= 0) {
          this.colorTransCred = true;
          this.flechaRojaTransCred = false;
        } else if (e["crecNumCred"] < 0) {
          this.colorTransCred = false;
          this.flechaRojaTransCred = true;
        } else {
          this.colorTransCred = false;
          this.flechaRojaTransCred = true;
        }
        e["crecCantCedValid"] = ((e.cantidadCedulasValidas * 100) / e.cantidadCedulasValidasPasado) - 100
        if (e["crecCantCedValid"] >= 0) {
          this.colorcrecCantCedValid = true;
          this.flechaRojacrecCantCedValid = false;
        } else if (e["crecCantCedValid"] < 0) {
          this.colorcrecCantCedValid = false;
          this.flechaRojacrecCantCedValid = true;
        } else {
          this.colorcrecCantCedValid = false;
          this.flechaRojacrecCantCedValid = true;
        }
        e["partTransCred"] = (e.cantidadTransaccionesCreditos * 100) / parseFloat(e.numeroTransaccionesMes)
        e["partPresCred"] = (e.PptoCreditoNro * 100) / parseFloat(e.PptoTrans)
        e["partNumCredPasado"] = (e.cantidadTransaccionesCreditosPasado * 100) / parseFloat(e.numeroTransaccionesMesPasado)
        return e

      });

      localStorage.setItem('dataSave', JSON.stringify(this.diar));
    }).catch((err => {
      // if (this.diar === null || this.diar == undefined)
      console.log('demorado')
      if (localStorage.getItem('dataSave') != null)
        this.diar = JSON.parse(localStorage.getItem('dataSave'));
    }));




  }
  enviarexceotrasvariables() {
    const datos = [{
      Ventas: 'Credito $',
      Actual: this.diar[0].totalCreditoKaiowa,
      Presupuesto: this.diar[0].presupuestoKaiowa,
      Anterior: this.diar[0].totalCreditoKaiowaPasado,
      Cumplimiento: this.diar[0].cumplimientoKaiowa,
      Crecimiento: this.diar[0].cKaiowa,
    }, {
      Ventas: 'Part Crédito/Venta total',
      Actual: this.diar[0].partCred,
      Presupuesto: this.diar[0].partCredPpto,
      Anterior: this.diar[0].partCredpasado,

    }, {
      Ventas: '# Crédito',
      Actual: this.diar[0].cantidadTransaccionesCreditos,
      Presupuesto: this.diar[0].PptoCreditoNro,
      Anterior: this.diar[0].cantidadTransaccionesCreditosPasado,
      Cumplimiento: this.diar[0].cumNumCred,
      Crecimiento: this.diar[0].crecNumCred,
    },
    {
      Ventas: 'Part # Crédito/Trans totales',
      Actual: this.diar[0].partTransCred,
      Presupuesto: this.diar[0].partPresCred,
      Anterior: this.diar[0].partNumCredPasado,

    },
    {
      Ventas: '%Cédulas Válidas',
      Actual: this.diar[0].cantidadCedulasValidas,
      Presupuesto: 0,
      Anterior: this.diar[0].cantidadCedulasValidasPasado,
      Cumplimiento: 0,
      Crecimiento: this.diar[0].crecCantCedValid

    },
    {
      Ventas: '%Cédulas Comodín(no válidas)',
      Actual: this.diar[0].cedulasComodin,
      Presupuesto: 0,
      Anterior: this.diar[0].cedulasComodinPasado,
      Cumplimiento: 0,
      Crecimiento: this.diar[0].cedulasCrecim
    }
    ]
    this.excelService.exportAsExcelFile(datos, 'Otras Variables');

  }
  enviarexceventas() {
    const datos = [{
      Ventas: 'Fisica',
      Actual: this.diar[0].tVentasFisicas,
      Presupuesto: this.diar[0].presupuestoFisico,
      Anterior: this.diar[0].tVentasFisicasPasado,
      Cumplimiento: this.diar[0].pFisico,
      Crecimiento: this.diar[0].cFisico,
      Participacion: this.diar[0].partFisico,
      Pendiente: this.diar[0].pendFisico,
      Unidades: this.diar[0].artFis,
      Transacciones: this.diar[0].numeroTransaccioneFisicas
    }, {
      Ventas: 'Digital',
      Actual: this.diar[0].totalVentasVirtuales,
      Presupuesto: this.diar[0].presupuestoDigital,
      Anterior: this.diar[0].totalVentasVirtualesPasado,
      Cumplimiento: this.diar[0].pDigital,
      Crecimiento: this.diar[0].cDigital,
      Participacion: this.diar[0].partDigital,
      Pendiente: this.diar[0].pendDigital,
      Unidades: this.diar[0].cantidadArticulosDigitales,
      Transacciones: this.diar[0].numeroTransaccioneDigitales
    }, {
      Ventas: 'Total',
      Actual: this.diar[0].totalVentasRango,
      Presupuesto: this.diar[0].totalPpto,
      Anterior: this.diar[0].totalVentasRangoPasado,
      Cumplimiento: this.diar[0].pTotal,
      Crecimiento: this.diar[0].cTotal,
      Participacion: 100,
      Pendiente: this.diar[0].pendTotal,
      Unidades: this.diar[0].tArt,
      Transacciones: this.diar[0].numeroTransaccionesMes
    }]
    this.excelService.exportAsExcelFile(datos, 'Ventas');

  }

  enviarexceindicadores() {
    const datos = [{
      Indicadores: 'VPT',
      Actual: this.diar[0].vpt,
      Presupuesto: this.diar[0].prptoVPT,
      Anterior: this.diar[0].vptPasado,
      Cumplimiento: this.diar[0].cumVpt,
      Crecimiento: this.diar[0].cVpt
    }, {
      Indicadores: 'UPT',
      Actual: this.diar[0].upt,
      Presupuesto: this.diar[0].prptoUPT,
      Anterior: this.diar[0].uptPasado,
      Cumplimiento: this.diar[0].cumUpt,
      Crecimiento: this.diar[0].cUpt
    }, {
      Indicadores: 'PP',
      Actual: this.diar[0].pP,
      Presupuesto: this.diar[0].prptoPP,
      Anterior: this.diar[0].pPPasado,
      Cumplimiento: this.diar[0].cumPp,
      Crecimiento: this.diar[0].cPp
    }, {
      Indicadores: 'TC',
      Actual: this.diar[0].tC,
      Presupuesto: this.diar[0].prptoTc,
      Anterior: this.diar[0].tCPasado,
      Cumplimiento: this.diar[0].cumTc,
      Crecimiento: this.diar[0].cTc
    }, {
      Indicadores: 'ENTRADAS',
      Actual: this.diar[0].numeroEntradasMes,
      Presupuesto: this.diar[0].PptoEntradas,
      Anterior: this.diar[0].numeroEntradasMesPasado,
      Cumplimiento: this.diar[0].cumEntradas,
      Crecimiento: this.diar[0].cEntradas
    },
    {
      Indicadores: 'TRANSACCIONES',
      Actual: this.diar[0].numeroTransaccionesMes,
      Presupuesto: this.diar[0].PptoTrans,
      Anterior: this.diar[0].numeroTransaccionesMesPasado,
      Cumplimiento: this.diar[0].cumTrans,
      Crecimiento: this.diar[0].cTrans

    }, {
      Indicadores: 'CREDITO $',
      Actual: this.diar[0].totalCreditoKaiowa,
      Presupuesto: this.diar[0].presupuestoKaiowa,
      Anterior: this.diar[0].totalCreditoKaiowaPasado,
      Cumplimiento: this.diar[0].cumplimientoKaiowa,
      Crecimiento: this.diar[0].cKaiowa

    },
    {
      Indicadores: 'PART CREDITO',
      Actual: this.diar[0].partCred,
      Presupuesto: this.diar[0].partCredPpto,
      Anterior: this.diar[0].partCredpasado


    }]
    this.excelService.exportAsExcelFile(datos, 'Indicadores');
  }

  enviarexcepotenciales() {
    const datos = [{
      Potenciales: 'TRANS ADIC/A FAVOR TC (A LA FECHA)',
      Actual: this.diar[0].trans
    }, {
      Potenciales: 'TRANS ADIC/A FAVOR TC (DIARIO)',
      Actual: this.diar[0].transDiario
    }, {
      Potenciales: 'POTENCIAL TOTAL (TC Y VPT PPTO)',
      Actual: this.diar[0].potTotal
    }]
    this.excelService.exportAsExcelFile(datos, 'Potenciales');

  }
  /**
   * Is Hovered
   *
   * @param date
   */
  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  /**
   * Is Inside
   *
   * @param date
   */
  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  /**
   *  Is Range
   *
   * @param date
   */
  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }
  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
  isDisabled = (date: NgbDate, current: { month: number, year: number }) => date.month !== current.month;


  limpiarFiltPv() {
    this.codigoTienda = null;
  }

  limpiarFiltFor(infotienda: any = this.inforTiendas.infoTienda) {

    var arregloAgencia = [];
    if (this.rol == "GERENTE" && !this.excepciones.join(',').includes(this.coordinador)) {
      var agencia = infotienda.map(x => { return { codigoAgencia: x.Regional, nombreAgencia: x.NombreAgencia, Gerente: x.Gerente }; });

      agencia.map((a) => {
        if (arregloAgencia.filter(p => p.codigoAgencia == a.codigoAgencia && p.Gerente == this.coordinador).length == 0)
          arregloAgencia.push(a);
      });
      // console.log(arregloAgencia);
      this.filtroAplicados = this.filtroAplicados.filter(p => p != "Formato");
      this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
      if (this.filtroAplicados.filter(p => p == "Agencia").length > 0) {
        this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.agenciaTienda.filter(d => p.Regional == d.codigoAgencia && p.Gerente == this.coordinador).length > 0 });
        this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
        this.formatoTienda = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }

      if (this.filtroAplicados.filter(p => p == "ciudad").length > 0) {

        this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.ciudad.filter(d => p.CIUDAD == d && p.Gerente == this.coordinador).length > 0 });
        this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
        this.formatoTienda = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }
      if (this.filtroAplicados.length == 0) {
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        this.formatos = [...new Set(infotienda.map(x => x.Formato))];
        this.formatoTienda = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }
    } else {
      if (this.rol == "COORDINADOR" && !this.excepciones.join(',').includes(this.coordinador)) {
        this.filtroAplicados = this.filtroAplicados.filter(p => p != "Formato");
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        if (this.filtroAplicados.filter(p => p == "Agencia").length > 0) {
          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.agenciaTienda.filter(d => p.Regional == d.codigoAgencia && p.Responsable == this.coordinador).length > 0 });
          this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
          this.formatoTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }

        if (this.filtroAplicados.filter(p => p == "ciudad").length > 0) {

          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.ciudad.filter(d => p.CIUDAD == d && p.Responsable == this.coordinador).length > 0 });
          this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
          this.formatoTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
        if (this.filtroAplicados.length == 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
          this.formatos = [...new Set(infotienda.map(x => x.Formato))];
          this.formatoTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
      } else {
        this.filtroAplicados = this.filtroAplicados.filter(p => p != "Formato");
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        if (this.filtroAplicados.filter(p => p == "Agencia").length > 0) {
          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.agenciaTienda.filter(d => p.Regional == d.codigoAgencia).length > 0; });
          this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
          this.formatoTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }

        if (this.filtroAplicados.filter(p => p == "ciudad").length > 0) {

          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.ciudad.filter(d => p.CIUDAD == d).length > 0 });
          this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
          this.formatoTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
        if (this.filtroAplicados.length == 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
          this.formatos = [...new Set(infotienda.map(x => x.Formato))];
          this.formatoTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
      }

    }
  }

  mostrarFiltross() {
    if (this.mostrarfiltros == true && this.mostrarfiltrosi == false) {
      this.mostrarfiltros = false
      this.mostrarfiltrosi = true
    } else {
      this.mostrarfiltros = true
      this.mostrarfiltrosi = false
    }

  }
  limpiarFiltAgen(infotienda: any = this.inforTiendas.infoTienda) {

    let arregloAgencia = [];

    if (this.rol == "GERENTE" && !this.excepciones.join(',').includes(this.coordinador)) {
      let agencia = infotienda.map(x => { return { codigoAgencia: x.Regional, nombreAgencia: x.NombreAgencia }; });


      agencia.forEach(a => {
        if (arregloAgencia.filter(b => b.codigoAgencia.trim() == a.codigoAgencia.trim()).length < 1) {
          arregloAgencia.push(a);
        }
      });

      // console.log(arregloAgencia);
      this.filtroAplicados = this.filtroAplicados.filter(p => p != "Agencia");
      if (this.filtroAplicados.filter(p => p == "ciudad").length > 0) {

        this.infoTiendaFiltrada = this.inforTiendas.infoTienda.filter(p => p.CIUDAD == this.ciudad && p.Gerente == this.coordinador);
        this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
        this.agenciaTienda = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }

      if (this.filtroAplicados.filter(p => p == "Formato").length > 0) {

        this.infoTiendaFiltrada = this.inforTiendas.infoTienda.filter(p => p.Formato == this.formatoTienda && p.Gerente == this.coordinador);
        this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
        this.agenciaTienda = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }
      if (this.filtroAplicados.length == 0) {
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda
        this.agenciaTienda = null;
        this.agencias = [...new Set(arregloAgencia)];
        this.fillFilter(this.infoTiendaFiltrada);
      }
    } else {
      if (this.rol == "COORDINADOR" && !this.excepciones.join(',').includes(this.coordinador)) {
        let agencia = infotienda.map(x => { return { codigoAgencia: x.Regional, nombreAgencia: x.NombreAgencia }; });


        agencia.forEach(a => {
          if (arregloAgencia.filter(b => b.codigoAgencia.trim() == a.codigoAgencia.trim()).length < 1) {
            arregloAgencia.push(a);
          }
        });
        // console.log(arregloAgencia);
        this.filtroAplicados = this.filtroAplicados.filter(p => p != "Agencia");
        if (this.filtroAplicados.filter(p => p == "ciudad").length > 0) {

          this.infoTiendaFiltrada = this.inforTiendas.infoTienda.filter(p => p.CIUDAD == this.ciudad && p.Responsable == this.coordinador);
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.agenciaTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }

        if (this.filtroAplicados.filter(p => p == "Formato").length > 0) {

          this.infoTiendaFiltrada = this.inforTiendas.infoTienda.filter(p => p.Formato == this.formatoTienda && p.Responsable == this.coordinador);
          this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
          this.agenciaTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
        if (this.filtroAplicados.length == 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda
          this.agenciaTienda = null;
          this.agencias = [...new Set(arregloAgencia)];
          this.fillFilter(this.infoTiendaFiltrada);
        }
      } else {
        var agencia = infotienda.map(x => { return { codigoAgencia: x.Regional, nombreAgencia: x.NombreAgencia }; });

        agencia.map((a) => {
          if (arregloAgencia.filter(p => p.codigoAgencia == a.codigoAgencia).length == 0)
            arregloAgencia.push(a);
        });
        // console.log(arregloAgencia);
        this.filtroAplicados = this.filtroAplicados.filter(p => p != "Agencia");
        if (this.filtroAplicados.filter(p => p == "ciudad").length > 0) {

          this.infoTiendaFiltrada = this.inforTiendas.infoTienda.filter(p => { return this.ciudad.filter(d => p.CIUDAD == d).length > 0 });
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.agenciaTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }

        if (this.filtroAplicados.filter(p => p == "Formato").length > 0) {

          this.infoTiendaFiltrada = this.inforTiendas.infoTienda.filter(p => { return this.formatoTienda.filter(d => p.Formato == d).length > 0 });
          this.formatos = [...new Set(this.infoTiendaFiltrada.map(x => x.Formato))];
          this.agenciaTienda = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
        if (this.filtroAplicados.length == 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda
          this.agenciaTienda = null;
          this.agencias = [...new Set(arregloAgencia)];
          this.fillFilter(this.infoTiendaFiltrada);
        }
      }
    }
  }


  limpiarFiltCiu(infotienda: any = this.inforTiendas.infoTienda) {

    if (this.rol == "GERENTE" && !this.excepciones.join(',').includes(this.coordinador)) {
      var agencia = infotienda.map(x => { return { codigoAgencia: x.Regional, nombreAgencia: x.NombreAgencia, Gerente: x.Gerente }; });
      var arregloAgencia = [];
      agencia.map((a) => {
        if (arregloAgencia.filter(p => p.codigoAgencia == a.codigoAgencia && p.Gerente == this.coordinador).length == 0)
          arregloAgencia.push(a);
      });
      // console.log(arregloAgencia);
      this.filtroAplicados = this.filtroAplicados.filter(p => p != "ciudad");
      this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
      if (this.filtroAplicados.filter(p => p == "Agencia").length > 0) {

        this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.agenciaTienda.filter(d => p.Regional == d.codigoAgencia && p.Gerente == this.coordinador).length > 0 });
        this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
        this.ciudad = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }

      if (this.filtroAplicados.filter(p => p == "Formato").length > 0) {

        this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.formatoTienda.filter(d => p.Formato == d && p.Gerente == this.coordinador).length > 0 });
        this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
        this.ciudad = null;
        this.fillFilter(this.infoTiendaFiltrada);
      }
      if (this.filtroAplicados.length == 0) {
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda
        this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
        this.ciudad = null;
        this.fillFilter(this.infoTiendaFiltrada);

      }
    } else {
      if (this.rol == "COORDINADOR" && !this.excepciones.join(',').includes(this.coordinador)) {
        this.filtroAplicados = this.filtroAplicados.filter(p => p != "ciudad");
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        if (this.filtroAplicados.filter(p => p == "Agencia").length > 0) {

          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.agenciaTienda.filter(d => p.Regional == d.codigoAgencia && p.Responsable == this.coordinador).length > 0 });
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.ciudad = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }

        if (this.filtroAplicados.filter(p => p == "Formato").length > 0) {

          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.formatoTienda.filter(d => p.Formato == d && p.Responsable == this.coordinador).length > 0 });
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.ciudad = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
        if (this.filtroAplicados.length == 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.ciudad = null;
          this.fillFilter(this.infoTiendaFiltrada);

        }
      } else {
        this.filtroAplicados = this.filtroAplicados.filter(p => p != "ciudad");
        this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
        if (this.filtroAplicados.filter(p => p == "Agencia").length > 0) {

          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.agenciaTienda.filter(d => p.Regional == d.codigoAgencia).length > 0; });
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.ciudad = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }

        if (this.filtroAplicados.filter(p => p == "Formato").length > 0) {

          this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => { return this.formatoTienda.filter(d => p.Formato == d).length > 0 });
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.ciudad = null;
          this.fillFilter(this.infoTiendaFiltrada);
        }
        if (this.filtroAplicados.length == 0) {
          this.infoTiendaFiltrada = this.inforTiendas.infoTienda
          this.ciudades = [...new Set(this.infoTiendaFiltrada.map(x => x.CIUDAD))];
          this.ciudad = null;
          this.fillFilter(this.infoTiendaFiltrada);

        }
      }
    }
  }
  filterResp() {
    this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
      return p.Responsable.toString().toLowerCase() == this.coordinador.toLowerCase();
    })
    this.fillFilter(this.infoTiendaFiltrada);
  }

  filterGer() {
    this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
      return p.Gerente == this.coordinador;
    })
    this.fillFilter(this.infoTiendaFiltrada);
  }
  filterPV(event) {
    // console.log("evento:" + event);
    if (this.ciudad != "null") {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return this.ciudad.filter(d => p.CIUDAD == d).length > 0;
      })
    } else {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return p.CIUDAD != '';
      })
    }

    this.fillFilter(this.infoTiendaFiltrada);
    this.filtroAplicados.push("ciudad");
  }
  onChange(event) {

    this.fechainicio = new Date(this.fechainicioStr + 'T00:00');
    this.fechafin = new Date(this.fechafinStr + 'T00:00');
    if (this.fechafin.getTime() >= this.fechainicio.getTime()) {
      this.difecha = (this.fechainicio.getTime() - this.fechafin.getTime()) + (1);
      this.dif = (this.difecha / (1000 * 3600 * 24)) * (-1);
      // console.log("evento:" + event);
      if (this.dif <= 0) {
        this.vista = 'Ar'
      } else {
        this.vista = 'Ac'
      }
      return this.vista;
    } else {

      window.alert("seleccione un rango de fechas valido");

    }
  }
  filterTiend(event) {
    if (this.codigoTienda != "null") {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return this.codigoTienda.filter(d => p.codigo == d).length > 0;
      })
    } else {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return p.Regional != '';
      })
    }
    this.fillFilter(this.infoTiendaFiltrada);
  }

  filterAge(event) {
    // this.infoTiendaFiltrada = this.inforTiendas.infoTienda;
    // console.log("evento:" + event);
    if (this.agenciaTienda != "null") {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return this.agenciaTienda.filter(d => p.Regional == d.codigoAgencia).length > 0;
      })
    } else {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return p.Regional != '';
      })
    }

    this.fillFilter(this.infoTiendaFiltrada);
    this.filtroAplicados.push("Agencia");
  }



  filterFormat(event) {

    // console.log("evento:" + event);
    if (this.formatoTienda != "null") {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return this.formatoTienda.filter(d => p.Formato == d).length > 0;
      });
    } else {
      this.infoTiendaFiltrada = this.infoTiendaFiltrada.filter(p => {
        return p.Formato != '';
      })
    }
    this.fillFilter(this.infoTiendaFiltrada);
    this.filtroAplicados.push("Formato");

  }
  ngOnDestroy() {
    clearInterval(this.intervalId);
  }
}
